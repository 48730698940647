import React, { useEffect, useState } from "react";
import { Connection, ConnectionStop } from "@flixbus/honeycomb-react";
import { Icon, IconTransfer } from "@flixbus/honeycomb-icons-react";
import { DateShortTime } from "../../../../constants";
import Moment from "moment";
import "./OrderItemDetails.scss";
import {
	calculateTotalDuration,
	calculateTransferTime,
} from "../../../../utils";
import { RideTag, StationDateAndTime } from "../../../../components";

export const OrderItemDetails = ({ orderItem }) => {
	const initialDepartureTime = orderItem.departAt;
	const [finalArrivalTime, setFinalArrivalTime] = useState(null);

	useEffect(() => {
		if (orderItem.routes && orderItem.routes.length > 0) {
			const arrival = orderItem.routes[orderItem.routes.length - 1].arrival;

			setFinalArrivalTime(arrival);
		}
	}, [orderItem]);

	return orderItem && orderItem.routes ? (
		<>
			{/* Interconnections */}
			<td colSpan={4}>
				<div className="InterconnectionDetails">
					<h4 className="InterconnectionDetails__header">
						Interconnection Details
					</h4>

					{/* full route with duration */}
					<div className="FullRoute">
						<div className="FullRoute__time">
							<div>
								<span className="FullRoute__stationName">
									<b>
										{Moment.parseZone(orderItem.departAt).format(DateShortTime)}
									</b>
								</span>
							</div>
							<div className="FullRoute__line">
								<span className="FullRoute__duration">
									{calculateTotalDuration(
										initialDepartureTime,
										finalArrivalTime
									)}
								</span>
							</div>
							<div>
								<span>
									<b>
										{Moment.parseZone(
											orderItem?.routes[orderItem?.routes.length - 1]?.arrival
										).format(DateShortTime)}
									</b>
								</span>
							</div>
						</div>
						<div className="FullRoute__station">
							<div className="FullRoute__station__stopName FullRoute__station__stopName--left">
								<b>{orderItem.stopFromCityName}</b>
							</div>
							<div className="FullRoute__station__stopName FullRoute__station__stopName--right">
								<b>{orderItem.stopToCityName}</b>
							</div>
						</div>
					</div>
					{/* interconnections */}
					{orderItem.routes &&
						orderItem.routes.map((route, index) => (
							<>
								<Connection extraClasses="InterconnectionRoute">
									<ConnectionStop
										station={
											<>
												{/* ride with a link to RideViewer */}
												<span className="InterconnectionRoute__stopName">
													{route.stopFromCityName}
												</span>
												<RideTag route={route} />
											</>
										}
										time={<StationDateAndTime time={route.departure} />}
									/>
									<ConnectionStop
										station={<span>{route.stopToCityName}</span>}
										time={<StationDateAndTime time={route.arrival} />}
									/>
								</Connection>
								{/* if this is not the last route - then need to show transfer time between connections */}
								{index !== orderItem.routes.length - 1 && (
									<div className="TransferTime">
										<Icon
											InlineIcon={IconTransfer}
											size={4}
											extraClasses="TransferIcon"
										/>
										<span>Transfer time: </span>
										{calculateTransferTime(route, orderItem.routes[index + 1])}
									</div>
								)}
							</>
						))}
				</div>
			</td>
			{/* TODO: transfer Comments here */}
			<td colSpan={4} className="CommentsCell">
				{/* <div className="CommentsDetails">
					<h5>Comments</h5>
				</div> */}
			</td>
		</>
	) : null;
};
