export const validateRefundRequest = (isCustomRefund, customAmount, paidAmount, itemsAmount, refundReason, confirmation) => {
    // request is valid if custom amount is set and less than the payment amount
	// or if items are selected and the sum is less than the max refund amount
	if (!confirmation || !refundReason) {
        return false;
    }
    if (
        (isCustomRefund && customAmount > 0 && customAmount < paidAmount) ||
        (!isCustomRefund &&
            itemsAmount > 0 &&
            refundReason &&
            itemsAmount <= paidAmount)
    ) {
        return true;
    } else {
        return false;
    }
}